export default {
    'AllowAccess': '允许匿名访问',
    'AnonymousPull': '匿名用户可拉取制品',
    'CannotPullArtifacts': '匿名用户不可拉取制品',
    'On': '开启',
    'Off': '关闭',
    'VisibilityRange': '仓库可见范围',
    'StorageMembers': '存储空间成员可见，可拉取',
    'AllMembers': '所有成员可见，可拉取',
    'StorageSpace': '存储空间',
    'Public': '公开',
    'PublicPermissionDefinition': '仓库权限定义',
    'SpecificAuthority': '在此定义用户对于该仓库的制品上传、制品删除权限',
    'AddUser': '添加用户',
    'SelectAll': '选择全部',
    'Path': '路径',
    'NoPath': '不填写路径，是对整个仓库的权限设置，',
    'HavePath': '填写了路径，是对仓库下路径的权限设置，',
    'PathFormat': '支持设置多个路径之间用逗号（","）分隔，',
    'ExamplePath': '示例路径：example/a,example/b。',
    'havePermissions': '拥有该仓库的',
    'UploadAndDelete': '上传、删除权限',
    'UploadPermissions': '上传权限',
    'DeletePermissions': '删除权限',
    'EnterPath': '请输入路径',
    'Yes': '是',
    'No': '否',
    'SureDelete': '确定要删除吗?',
    'Confirm': '确定',
    'Cancel': '取消',
    'Save': '保存',
    'SelectRepositoryVisibility': '请选择仓库可见范围',
    'UserName': '用户名',
    'Upload': '上传',
    'Delete': '删除',
    'Operation': '操作',
    'OperationSuccessful': '操作成功',
    'setUpSuccessful': '仓库设置成功',
    'ModifyScope': '仓库可见范围改为存储空间内，用户',
    'removedUser': '不属于该存储空间，需要先从授权列表中移除',
    'Give': '至少给',
    'permission': '赋予一项权限',
    'AssigningMembers': '没有满足条件的成员，请先给存储空间分配合适的成员',
}
