export default {
    'ProductWarehouse': '制品仓库',
    'SecurityScanning': '安全扫描',
    'UserManagement': '用户管理',
    'UserList': '用户列表',
    'Permissions': '权限管理',
    'PermissionList': '权限列表',
    'Groups': '用户组管理',
    'GroupList': '用户组列表',
    'AccessToken':'访问令牌',
    'TokenList':'令牌列表',
    'GlobalSettings': '全局设置',
    'SetupManagement': '设置管理',
    'HealthMonitoring': '健康监测',
    'SeniorOperations': '高级运维',
    'ProductAnalysis': '制品扫描',
    'OpenSourceGovernance': '制品分析',
    'BOMAnalysis': 'BOM分析',
    'VulnerabilityDatabase': '漏洞库',
    'CertificateStore': '证书库',
    'StorageAnalysis': '存储分析',
    'Homepage': '首页',
    'RepositoryList': '仓库列表',
    'WarehouseBrowsing': '仓库浏览',
    'ScanTheHomePage': '扫描首页',
    'ScanDetails': '扫描详情',
    'Login': '登录',
    'PersonalCenter': '个人中心',
    'ProductDetails': '制品详情',
    'OpenSourceComponents': '开源组件',
    'ComponentDetails': '组件详情',
    'VulnerabilityDetails': '漏洞详情',
    'CertificateDetails': '证书详情',
    'PromptInformation': '提示信息',
    'upgradePremiumVersion': '此功能为高级版尊享，如需体验，请升级为高级版',
    'Well': '好的',
    //bom start
    'ProjectsAnalysis': 'BOM分析',
    'RepositoryAnalysisDetail': '仓库分析详情',
    'RepositoryDetails': '仓库分析',
    'ProjectsAnalysisDetail': '制品分析详情',
    'ProjectsDetails': '制品分析',
    //bom end
    'StorageMonitoring': '存储驾驶舱',
    'StatisticalOverview':'统计概览',
    'AdvancementCockpits':'晋级驾驶舱',
    'AdvancementCockpitsInfo':'晋级驾驶舱详情',
}
