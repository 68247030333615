export default {
   'UsingUsernamePasswordLogin': 'Using username and password to log in',
   'LocalAuthentication': 'Local authentication',
   'enterLDAPUsername': 'Please enter LDAP username!',
    'Welcome': 'Welcome',
    'CheckAgreementProceed': 'Please check the agreement to proceed.',
    'Login': 'Login',
    'Username': 'Username',
    'EnterYourUsername': 'Please enter your username!',
    'Password': 'Password',
    'EnterThePassword': 'Please enter the password!',
    'Authentication': ' authentication',
}
