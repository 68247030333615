export default {
    // Vulnerabilities有两个重名文件，翻译字段要两个文件同步。
    // Vulnerability.vue
    'EnterStorageSpace': 'Enter the storage space query',
    'EnterWarehouse': 'Enter the warehouse query',
    'EnterVulnerabilityNumber': 'Enter the vulnerability number query',
    'TimeRangeSearch': 'Time range search',
    'Seriously': 'Seriously',
    'MediumRisk': 'Medium risk',
    'HighRisk': 'High risk',
    'LowRisk': 'Low risk',
    'VulnerabilityDescription': 'Vulnerability description',
    'Download': 'Download',
    'Atlas': 'Atlas',
    'TheWhiteList': 'White list',
    'SureAddedWhitelist': 'Are you sure you want to be added to the whitelist?',
    'BeSure': 'Confirm',
    'Cancel': 'Cancel',
    'SureRemovedWhitelist': 'Are you sure you want to be removed from the whitelist?',
    'Blacklist': 'Blacklist',
    'SureAddedBlacklisted': 'Are you sure you want to be blacklisted?' ,
    'SureRemovedBlacklisted': 'Are you sure you want to be removed from the blacklist?' ,
    'InTheLastWeek': 'In the last week',
    'InTheLastMonth': 'In the last month',
    'InTheLastThreeMonths': 'In the last three months',
    'InTheLastSixMonths': 'In the last six months',
    'Vulnerabilities': 'The scope of the vulnerability ',
    'VulnerabilityScope': '.xlsx',
    'RecommendedFixVersion': 'Recommended fix version ',
    'VulnerabilityNumber': 'Vulnerability number',
    'AddWhitelistSuccess': ' Added to whitelist successfully',
    'AddBlacklistSuccess': ' Added to blacklist successfully',
    'RemovedWhitelistSuccess': ' Successfully removed from whitelist',
    'RemovedBlacklistSuccess': ' Successfully removed from the blacklist',
    'OperateSuccess': 'Operation successful',
    // VunlerabilityReport.vue
    'ReportDetail': 'Details of the report',
    'VulnerabilitiesNum': 'Number of vulnerabilities',
    'Health': 'Health',
    'DependencyRiskVulnerability1': 'This dependency contains ',
    'DependencyRiskVulnerability2': 'risk credentials, and ',
    'DependencyRiskVulnerability3': ' vulnerabilities were found in the scan detection',
    'VersionNumber': 'Version number',
    'references': ' references',
    'Instructions': 'Instructions',
    'InformationSource': 'The following information comes from the open source community',
    'Links': 'Links to related information',
    'CVENumber': 'CVE number',
    'VulnerabilityLevel': 'Vulnerability level',
    'CvssV2Score': 'CvssV2 score',
    'CvssV3Score': 'CvssV3 score',
    'ImportVersion': 'Import version',
    // index.vue
    'Unassigned': 'Unassigned',
    'CVSSScore': 'CVSSScore',
    'VulnerabilityType': 'Vulnerability type',
    'AffectedProducts': 'Affected products',
    'Level': 'Level',
    // index.vue
    'GeneralView': 'General view',
    'Information': 'Information',
    // VulnerabilitiesOverView.vue
    'Overview': 'Overview',
    'basicScore': 'CVSS basic score',
    'impactScore': 'CVSS impact score',
    'availabilityScore': 'CVSS availability score',
    'Reference': 'Reference',
    // AffectedArtifacts.vue
    'ArtifactPathQuery': 'Enter artifact path query',
    'StorageSpace': 'storage space',
    'OwnedWarehouse': 'Owned warehouse',
    'ProductPath': 'Product path',
    'BugArtifacts': 'Bug Artifacts List',
    'DownloadCount': 'Download count',
}
