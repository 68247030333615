export default {
    'SyncNo': '制品同步编号',
    'SourcePath': '源制品路径',
    'SourceStorageId': '源存储空间',
    'SourceRepositoryId': '源仓库',
    'TargetPath': '目标制品路径信息',
    'OpsType': '制品操作',
    'Status': '同步状态',
    'SlaveRecordCleared': '从记录状态',
    'SyncProgress': '同步进度',
    'FailedReason': '失败原因',
    'CreateTime': '创建时间',
    'Operational': '操作',
    'Ready': '就绪',
    'Syncing': '同步中',
    'Success': '成功',
    'Failed': '失败',
    'Removals': '清除',
    'NotCleared': '未清除',
    'ProductUpgrade': '制品晋级',
    'Distribution': '制品分发',
    'SynchronousMode': '同步模式',
    'SetTop': '置顶',
    'EnterVulnerabilitySourceStorageId': '输入源存储空间查询',
    'EnterVulnerabilitySourceRepositoryId': '输入源仓库查询',
    'Uncharted': '未定义',
    'FailedCount' : '失败数量',
    'SuccessCount' : '成功数量',
    'TotalCount' : '总数量',
    'FileSizeCount': '总文件大小(G)',
    '30daysData': '近30天数据',
    '30DaysPromotionRanking': '30内晋级排名前10仓库',
    'TrendsInPromotionStatusWithin30':'30内晋级状态趋势统计',
    'Remove':'删除',



}
