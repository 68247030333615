export default {
    'ProductManagement': 'Artifact management',
    'ProductWarehouse': 'Artifact repository',
    'SecurityScanning': 'Security scanning',
    'OpenSourceGovernance': 'Artifact analysis',
    'BOMAnalysis': 'BOM analysis',
    'ProductAnalysis': 'Artifact scan',
    'OpenSourceComponents': 'Open source components',
    'VulnerabilityDatabase': 'Vulnerability database',
    'CertificateStore': 'Certificate store',
    'SetupManagement': 'Setup management',
    'UserManagement': 'User management',
    'Permissions': 'Permission management',
    'Groups': 'Group management',
    'GlobalSettings': 'Global Settings',
    'HealthMonitoring': 'Health monitoring',
    'AccessToken':'Access Token',
    'TokenList':'Token List',
    'SeniorOperations': 'Senior Operations',
    'DoNotKnowHowToUseIt': "Don't know how?",
    'CheckTheHelpDocumentation': 'Check the help documentation',
    'userManual': 'user manual',
    'UploadProgress': 'Upload progress',
    'FileList': 'File list',
    'Empty': 'Empty',
    'ReasonsForFailure': 'Reasons for failure:',
    'StyleSettings': 'Style settings',
    'AdjustStyleYouLike': 'Adjust the style you like',
    'MenuDisplayStyle': 'Menu display style',
    'CurrentlySupportsStyles': 'Currently supports 2 styles, other styles will be updated later',
    'Transparent': 'Transparent',
    'WhiteBackground': 'White background',
    'NavigationLock': 'Navigation lock',
    'StorageMonitoring': 'Storage Cockpits',
    'StatisticalOverview':'Statistical Overview',
    'AdvancementCockpits':'Advancement Cockpits',
    'AdvancementCockpitsInfo':'Advancement Cockpits Info',
}
