export default {
    'Save': 'Save',
    'Cancel': 'Cancel',
    'BeSure': 'Confirm',
    'HasBeenUsed': 'Have been used ',
    'AboutPercentageOf': 'or about ',
    'EnableCaching': 'Enable caching',
    'sureClearCacheDirectory': 'Are you sure you want to empty the cache directory',
    'cacheDirectory': ' ?',
    'Empty': 'Empty',
    'CacheDirectories': 'Cache directories',
    'containerizedDeployment': 'For containerized deployment, the directory is the directory inside the container, and the cache directory needs to be mounted to the corresponding directory inside the container when the container is started.',
    'selectCacheDirectory': 'Please select the cache directory',
    'CacheCapacity': 'Cache capacity',
    'enterCacheCapacity': 'Please enter the cache capacity',
    'MinimumCacheValuePerFile': 'Minimum cache value per file',
    'GreaterThanOrEqualToMinimumCacheValue': 'Artifacts that are greater than or equal to the minimum cache value for a single file will be placed in the cache',
    'enterMinimumCacheValue': 'Please enter the minimum cache value for a single file',
    'MaximumCacheValueForSingleFile': 'Maximum cache value for a single file',
    'LessThanOrEqualMaximumCacheValue': 'Artifacts that are less than or equal to the maximum cache value for a single file will be placed in the cache',
    'enterMaximumCacheValue': 'Please enter the maximum cache value for a single file',
    'CleaningCondition': 'Cleaning condition (percentage)',
    'EnterCleaningCapacity': 'Enter a value between 1 and 100, for example 90 to start clearing at 90% or more of the cache capacity',
    'enterCleaningCondition': 'Please enter the cleaning condition (percentage)',
    'CleanupRatio': 'Cleanup ratio (percentage)',
    'AtLeastTheClearedCacheCapacity': 'A value of 1-100, such as 10, indicates that at least 10% of the cache capacity should be cleared when the purge condition is reached, and may be greater than 10%',
    'enterCleaningRatioPercent': 'Please enter the cleaning ratio (percentage),',
    'KeepMinimumUnitsConsistent': 'Keep the minimum cache value in the same units as the maximum cache value',
    'MinimumCacheValue': 'The minimum cache value needs to be less than the maximum cache value',
    'KeepMaximumUnitsConsistent': 'Keep the maximum cache value in the same unit as the minimum cache value',
    'MaximumCacheValue': 'The maximum cache value needs to be greater than the minimum cache value',
    'CacheSizeLengthLimit': 'Cache size between 1 and 10 characters',
    'enterCleaningConditions': 'Please enter the cleaning conditions',
    'enterCleaningRatio': 'Please enter the cleaning ratio',
    'ClearedCacheDirectorySuccess': 'Cleared cache directory successfully',
    'cachePolicySetSuccess': 'The cache policy is set successfully',
    'OperationSuccessful': 'Operation successful',

}
