export default {
    'EventRecord':'事件记录',
    'Distribution': '分发',
    'promotionRecords': '晋级记录',
    'DistributionNode': '分发节点',
    'StorageSpace': '存储空间',
    'WarehouseName': '仓库名称',
    'CheckOut': '查看',
    'UnknownOperation': '未知操作',
    'UnknownState': '未知状态',
    'Cleared': '已清除',
    'NotCleared': '未清除',
    'CurrentProductIsSynchronizing': '当前制品正在同步中，',
    'SureMakeProductCompensation': '确定要进行制品补偿吗',
    'Confirm': '确定',
    'Cancel': '取消',
    'Compensation': '补偿',
    'AuditLog': '审计日志',
    'ProductPromotion': '制品晋级',
    'DistributionOfProducts': '制品分发',
    'Ready': '就绪',
    'InSync': '同步中',
    'Success': '成功',
    'Failure': '失败',
    'ProductSynchronousNumber': '制品同步编号',
    'SourceProductPath': '源制品路径',
    'TargetProductPathInformation': '目标制品路径信息',
    'ProductOperation': '制品操作',
    'SynchronousState': '同步状态',
    'FromTheRecordState': '从记录状态',
    'SynchronizingProgress': '同步进度',
    'CauseOfFailure': '失败原因',
    'CreationTime': '创建时间',
    'Operation': '操作',
}
