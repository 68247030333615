export default {
    'FederatedRepositoryArtifactPromotion': '联邦仓库制品晋级',
    'FederatedRepositoryArtifactPromotionHasBeenInitiated': '联邦仓库制品晋级已开启',
    'FederatedRepositoryArtifactPromotionHasBeenClosed': '联邦仓库制品晋级已关闭',
    'On': '开启',
    'Off': '关闭',
    'PromotionRules': '晋级规则',
    'ArtifactPathTips1': '输入关键词用于匹配制品路径，支持正则表达式，',
    'ArtifactPathTips2': '普通示例：example可匹配制品路径中包含example的制品，',
    'ArtifactPathTips3': '正则示例：(.*example.*)(.*V1.*)可匹配镜像名包含example，',
    'ArtifactPathTips4': '镜像版本包含V1的镜像',
    'Metadata': '元数据',
    'MetadataTips1': '输入元数据KEY及元数据值，字符相等匹配',
    'MetadataTips2': '用于匹配制品元数据中包含该KEY及值的制品',
    'MetadataTips2': '用于匹配制品元数据中包含该KEY及值的制品',
    'MetadataTips2': '用于匹配制品元数据中包含该KEY及值的制品',
    'PleaseEnter': '请输入',
    'ArtifactPaths': '包路径',
    'MetadataKey': '元数据KEY',
    'MetadataValue': '元数据值',
    'FederatedRepositorySelect': '联邦仓库选择',
    'FederatedRepositorySelectTips': '选择其他集群的制品仓库组成联邦仓库',
    'InnerNodes': '内部节点',
    'ThirdpartyNodes': '外部节点',
    'ArtifactRepositoryNode': '制品库节点',
    'OtherType': '其他类型',
    'PleaseSlectRepository': '请选择联邦仓库',
    'Confirm': '确定',
    'Cancel': '取消',
    'Save': '保存',
    'DeleteConfirm': '确定要删除吗?',
    'Node': '节点',
    'RepositoryType': '制品库类型',
    'Storage': '存储空间',
    'Repository': '仓库名称',
    'Operation': '操作',
    'AritfactPromotionRuleTips': '请选择是否开启制品晋级',
    'PromotionRuleTips': '请选择晋级规则',
    'ArtifactPathRulesTips': '包路径长度在1到200个字符',
    'MetadataKeyRulesTips': '元数据KEY长度在1到100个字符',
    'MetadataValueRulesTips': '元数据值长度在1到200个字符',
    'OperateSuccess': '操作成功',
    'SyncData': '同步用户、同步角色权限、同步用户组、仓库信息到其他集群',
}

