export default {
    'AddPermission': 'New Permission',
    'EnterTheStorageQuery': 'Select The Storage Query',
    'EnterTheRepositoryQuery': 'Select The Repository Query',
    'EnterThePathQuery': 'Enter The Path Query',
    'EnterTheNameQuery': 'Enter The Name Query',
    'Name': 'Permission Name',
    'SingleName': 'Name',
    'Users': 'Users',
    'Groups': 'Groups',
    'Operation': 'Operation',
    'Edit': 'Edit Permission',
    'View': 'View Permission',
    'Create': 'New Permission',
    'Resources': 'Resources',
    'ResourcesDesc': 'Select the resource types to which the permission applies',
    'UsersDesc': 'Select the users and their actions on the selected resources',
    'GroupsDesc': 'Select the groups and their actions on the selected resources',
    'EnterTheNameCreate': 'Enter a unique name',
    'EnterTheNamePattern': 'Please enter uppercase and lowercase letters, _,| or -',
    'AddRepositories': 'Add Repositories',
    'AddBuilds': 'Add Builds',
    'AddReleaseBundles': 'Add Release Bundles',
    'EditRepositories': 'Edit Repositories',
    'EditBuilds': 'Edit Builds',
    'EditReleaseBundles': 'Edit Release Bundles',
    'RepositoriesDesc': 'Set a repository permission target to define what a user has access to view in the repository resource.',
    'SelectRepositories': 'Select Repositories',
    'SetPatterns': 'Set Path (Optional)',
    'SelectRepositoriesDesc': 'Select all the repositories to which you wish to apply the permissions.',
    'RepositoriesPatternsDesc': 'Setting path applies to the artifacts inside the selected repositories.',
    'AvailableRepositories': 'Available Repositories',
    'SelectedRepository': 'Selected Repository',
    'AllRepositories': 'All Repositories',
    'PerRepository': 'Per Repository',
    'OptionalTip': 'You can set the pattern using different granularity levels.',
    'IncludePatterns': 'Include Patterns',
    'PatternsTip': 'Simple comma separated wildcard patterns for existing and future repository artifact paths (with no leading slash).\n' +
        'Ant-style path expressions are supported (*, **, ?).\n' +
        'For example: org/apache/**',
    'ExcludePatterns': 'Exclude Path',
    'NewPatterns': 'New Path',
    'Search': 'Search',
    'BundleTip': 'Set a Release Bundle permission target to define what a user has access to view in the Release Bundle resource.',
    'SelectBundle': 'Select Release Bundles Repositories',
    'SelectBundleDesc': 'Select all the Release Bundle Repositories to which you wish to apply the permissions.',
    'BundlePatternsDesc': 'Setting patterns applies to the Release Bundles inside the selected Release Bundle Repositories.',
    'AllBundles': 'All Release Bundle Repositories',
    'PerBundle': 'Per Release Bundle Repository',
    'BuildsDesc': 'Set a build permission target to define which builds will be included in the permission target',
    'ByBuildName': 'By Build Name',
    'ByPattern': 'By Pattern',
    'BuildOptionTip': 'Select builds either by name or enter patterns',
    'All': 'All',
    'Selected': 'Selected',
    'BuildName': 'Build Name',
    'IncludeAllBuild': 'Include All Builds',
    'Preview': 'Preview',
    'TotalBuilds': 'Total Number of Builds',
    'BundlesPatternsTip': 'Use Ant-style wildcard patterns to specify existing and future build names (i.e. artifact paths) in the build info repository (without a leading slash) that will be included in this permission target.\n' +
        'Ant-style path expressions are supported (*, **, ?).\n' +
        'For example, an apache/** pattern will include the "apache" build info in the permission.',
    'CheckTip': 'Including all builds will add all existing and future builds to this permission.',
    'CheckConfirmTip': 'Are you sure you wish to proceed? This will remove all the include patterns you manually entered.',
    'SelectedUser': 'Selected Users',
    'SelectedUsersRepositories': 'Selected Users Repositories',
    'SelectedUsersBuilds': 'Selected Users Builds',
    'SelectedUsersBundles': 'Selected Users Release Bundles',
    'ResourceTip': 'Resource type not selected',
    'NoPermissionsTip': 'No permissions are selected. This user will not be saved in the permission target.',
    'Download': 'Read/Download',
    'Metadata':'Metadata',
    'Manage':'Manage',
    'DeployCache': 'Deploy/Cache',
    'DeleteUpdate': 'Delete/Update',
    'PromoDistribution': 'Promotion/Distribution',
    'SelectAll': 'Select All',
    'SelectUsers': 'Select Users',
    'SelectedGroups': 'Selected Groups',
    'SelectGroups': 'Select Groups',
    'GroupNoPermissionsTip': 'No permissions are selected. This group will not be saved in the permission target.',
    'SelectedGroupRepositories': 'Selected Group Repositories',
    'SelectedGroupBuilds': 'Selected Group Builds',
    'SelectedGroupBundles': 'Selected Group Release Bundles',
    'SelectedPermissions': 'Selected Permissions',
    'Repositories': 'Repositories',
    'StorageSpace': 'StorageSpace',
    'SelectStorageSpace': 'Select StorageSpace',
    'SelectStorageSpaceDesc': 'Select all the storage space to which you wish to apply the permissions.',
    'AtLeastOneRepository': 'Please select at least one repository',
    'UserNoneSelectAuth': 'Select at least one permission for the user',
    'UserSelected': 'The current repository has set up an administrator, confirm reselect?',
    'GroupNoneSelectAuth': 'Select at least one permission for the user group',
    'CheckAll': 'Check All',
    'UnselectAll': 'Unselect All',
    'DownloadDesc': 'Download artifacts',
    'ManageDesc':'Manage repository',
    'MetaDataDesc':'Edit metadata',
    'DeployCacheDesc': 'Deploys artifacts & deploys to remote repository caches',
    'DeleteUpdateDesc': 'Deletes or overwrites artifacts',
    'PromoDistributionDesc': 'Artifacts promote and distribute',
    'Enabled': 'Enabled',
    'Disabled': 'Disabled',
    'Permissions': 'Permissions',
    'PermissionsDesc': 'Select the permissions that correspond to the resource',
    'ManageDesc':'Repository management permission',
    'Description': 'Description',
    'SelectResources': 'Select Resources',
    'ResourceName': 'Resource Name',
    'Type': 'Type',
    'Path': 'Path',
    'EnterTheDescription': 'Enter the description',
    'NoPermissionsTooltip': 'No permissions selected.',
    'Repository': 'Repository',
    'StorageSpaceTooltip': 'Select a storage space to apply permissions to all repositories in the storage space',
    'RepositoryTooltip': 'Select a repository to apply permissions to all artifacts in the repository',
    'IncludeTooltip': 'Specify the path that the repository permission includes',
    'Group': 'Group',
    'User': 'User',
    'SearchRepositories':'Please enter the repository name',
    'Confirm':'Confirm',
    'Cancel':'Cancel',
    'SelectableUserDescription':'*Select the user to configure permissions for',
    'SelectedUserDescription':'*View or edit each user\'s corresponding permissions',
    'SelectUserPermissionDescription':'*Configure the specific permissions each user has',
    'SelectableGroupDescription':'*Select the group to configure permissions for',
    'SelectedGroupDescription':'*View or edit each group\'s corresponding permissions',
    'SelectGroupPermissionDescription':'*Configure the specific permissions each group has'
    
}
