export default {
    // Vulnerabilities有两个重名文件，翻译字段要两个文件同步。
    // Vulnerability.vue
    'EnterStorageSpace': '输入存储空间查询',
    'EnterWarehouse': '输入仓库查询',
    'EnterVulnerabilityNumber': '输入漏洞编号查询',
    'TimeRangeSearch': '时间范围搜索',
    'Seriously': '严重',
    'MediumRisk': '中危',
    'HighRisk': '高危',
    'LowRisk': '低危',
    'VulnerabilityDescription': '漏洞描述',
    'Download': '下载',
    'Atlas': '图谱',
    'TheWhiteList': '白名单',
    'SureAddedWhitelist': '确定要加入到白名单吗?',
    'BeSure': '确定',
    'Cancel': '取消',
    'SureRemovedWhitelist': '确定要从白名单移除吗?',
    'Blacklist': '黑名单',
    'SureAddedBlacklisted': '确定要加入到黑名单吗?',
    'SureRemovedBlacklisted': '确定要从黑名单移除吗?',
    'InTheLastWeek': '最近一周',
    'InTheLastMonth': '最近一个月',
    'InTheLastThreeMonths': '最近三个月',
    'InTheLastSixMonths': '最近半年',
    'Vulnerabilities': '漏洞',
    'VulnerabilityScope': '的影响范围.xlsx',
    'RecommendedFixVersion': '建议修复版本',
    'VulnerabilityNumber': '漏洞编号',
    'AddWhitelistSuccess': '添加到白名单成功',
    'AddBlacklistSuccess': '添加到黑名单成功',
    'RemovedWhitelistSuccess': '从白名单移除成功',
    'RemovedBlacklistSuccess': '从黑名单移除成功',
    'OperateSuccess': '操作成功',
    // VunlerabilityReport.vue
    'ReportDetail': '报告详情',
    'VulnerabilitiesNum': '漏洞数量',
    'Health': '健康',
    'DependencyRiskVulnerability1': '该依赖含有',
    'DependencyRiskVulnerability2': '个风险凭证，并在扫描检测中发现',
    'DependencyRiskVulnerability3': '个漏洞',
    'VersionNumber': '版本号',
    'references': '个参考信息',
    'Instructions': '说明',
    'InformationSource': '以下信息均来自于开源社区',
    'Links': '相关信息链接',
    'CVENumber': 'CVE编号',
    'VulnerabilityLevel': '漏洞等级',
    'CvssV2Score': 'CvssV2评分',
    'CvssV3Score': 'CvssV3评分',
    'ImportVersion': '引入版本',
    // index.vue
    'Unassigned': '未赋值',
    'CVSSScore': 'CVSS评分',
    'VulnerabilityType': '漏洞类型',
    'AffectedProducts': '受影响的制品',
    'Level': '等级',
    // index.vue
    'GeneralView': '总览',
    'Information': '信息',
    // VulnerabilitiesOverView.vue
    'Overview': '概览',
    'basicScore': 'CVSS基本得分',
    'impactScore': 'CVSS影响性得分',
    'availabilityScore': 'CVSS可利用性得分',
    'Reference': '参考',
    // AffectedArtifacts.vue
    'ArtifactPathQuery': '输入制品路径查询',
    'StorageSpace': '存储空间',
    'OwnedWarehouse': '所属仓库',
    'ProductPath': '制品路径',
    'BugArtifacts': '漏洞制品列表',
    'DownloadCount': '下载次数',
}
