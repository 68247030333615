export default {
    'StrategyList': '策略列表',
    'Seriously': '严重',
    'HighRisk': '高危',
    'MediumRisk': '中危',
    'LowRisk': '低危',
    'YES': '是',
    'NO': '否',
    'BlockingStrategyName': '阻断策略名称',
    'VulnerabilityBlockingLevel':'漏洞阻断级别',
    'FilterVulnerabilityWhitelist':'过滤漏洞白名单',
    'FilterVulnerabilityBlacklist':'过滤漏洞黑名单',
    'FilterLicenseWhitelist':'过滤license白名单',
    'FilterLicenseBlacklist':'过滤license黑名单',
    'FullPackageName':'全量包名',
    'BlockingStrategySetting':'阻断策略设置',
    'EnterBlockingStrategyName':'请输入阻断策略名称',
    'ChooseBlockRepository':'选择阻断仓库',
    'SelectableRepositories':'可选择的仓库',
    'SelectedRepositories':'已选择的仓库',
    'VulnerabilityBlockingLevelTip':'选择要阻断的漏洞级别，如果制品的漏洞包含了选择的漏洞级别，制品将被禁止下载',
    'FilterVulnerabilityList':'过滤漏洞名单',
    'FilterVulnerabilityListTip1':'过滤白名单表示会过滤掉加入到漏洞白名单中的漏洞编号，如果制品的漏洞编号包含了在白名单中，制品仍然可以下载',
    'FilterVulnerabilityListTip2':'过滤黑名单表示会过滤掉加入到漏洞黑名单中的漏洞编号，如果制品的漏洞编号包含了在黑名单中，制品将被禁止下载',
    'Whitelist':'白名单',
    'Blacklist':'黑名单',
    'FullPackageNameBlocking':'全量包名阻断',
    'FullPackageNameBlockingTip':'设置后将会用包名与包名阻断规则做对比，如果制品的包名包含在了包名阻断规则中，制品将被禁止下载',
    'ChooseToBlockPackageName':'选择阻断包名',
    'ChooseToBlockPackageNameTip':'选择要阻断的包名规则，如果制品的包名包含在了已选择的包名阻断规则中，制品将被禁止下载',
    'SelectablePackageName':'可选择的包名',
    'SelectedPackageName':'已选择的包名',
    'FilterLicenseList':'过滤License名单',
    'FilterLicenseListTip1':'过滤白名单表示会过滤掉加入到License白名单中的License，如果制品的License包含了在白名单中，制品仍然可以下载',
    'FilterLicenseListTip2':'过滤黑名单表示会过滤掉加入到License黑名单中的License，如果制品的License包含了在黑名单中，制品将被禁止下载',
    'ChooseToBlockLicense':'选择阻断License',
    'ChooseToBlockLicenseTip':'选择要阻断的License，如果制品的License包含在了已选择的License中，制品将被禁止下载',
    'SelectableLicense':'可选择的License',
    'SelectedLicense':'已选择的License',
    'EnterBlockStrategyNameQuery': '输入阻断策略名查询',
    'EnterStorageIdQuery': '输入存储空间查询',
    'EnterRepositoryIdQuery': '输入仓库查询',
    'EnterPackageNameQuery': '输入包名称查询',
    'EnterLicenseIdQuery': '请输入证书名称',
    'Operate': '操作',
    'OperationSuccessful': '操作成功',
}