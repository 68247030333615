export default {
    'CreatePackage': 'Create package',
    'SureDelete': 'Are you sure you want to delete it?',
    'Cancel': 'Cancel',
    'Confirm': 'Confirm',
    'PackageName': 'Package name',
    'PackageNamePlaceholder': 'Please enter the package name',
    'PackageNameLength': 'The package name is between 1 and 300 characters in length',
    'ConditionType': 'Condition type',
    'ConditionTypePlaceholder': 'Please select the condition type',
    'Version': 'Version',
    'VersionPlaceholder': 'Please enter the version',
    'VersionLength': 'The version number is between 1 and 100 characters in length',
    'OperateSuccess': 'Operation successful',
    'Operate': 'Operation',
    'Success': ' success',
    'PackageNameQuery': 'Input package name query',
    'DeletePackage':'Delete package',
}