export default {
    'StorageMonitoring': 'Storage Cockpits',
    'StorageDevice': 'Storage device',
    'StorageDeviceName': 'Storage device name',
    'StorageDeviceType': 'Storage device type',
    'StorageDeviceSize': 'Storage device size',
    'StorageDeviceUsableSize': 'Storage device usable size',
    'UsedStorageDeviceSize': 'Percentage of used storage device',
    'UsedStorageDeviceSizePercentage': 'Percentage of used storage',
    'ArtifactFilesSize': 'Artifact used size',
    'UsedArtifactSizePercentage': 'Used artifact size percentage',
    'StorageCount': 'Storage count',
    'RepositoryCount': 'Repository count',
    'OtherFilesSize': 'Other files size',
    'UsedOtherFilesSizePercentage': 'Used other files size percentage',
    'Storage':'Storage',
    'Repository':'Repository',
    'RepositoryLayout':'Repository layout',
    'RepositoryType':'Repository type',
    'UsedStorageQuotaSizePercentage':'Percentage of used storage quota',
    'UsedStorageSizePercentage':'Percentage of used storage device',
    'FilesSize':'Files size',
    'FilesCount':'Files count',
    'ArtifactsSize':'Artifacts size',
    'ArtifactsCount':'Artifacts count',
    'FoldersCount':'Folders count',
    'ItemsCount':'Items count',
    'StorageOverview':'Storage overview',
    'Total':'Total',
    'TrashCan':'Trash Can',
    'DataUpdateTime': 'Latest update time of data',
    'RefreshData': 'Started refreshing data in the background',
    'StorageQuery': 'Please select storage query',
    'RepositoryQuery': 'Please select repository query',
    'RepositoryLayoutQuery': 'Please select package type query',
    'RepositoryTypeQuery': 'Please select repository type query',
    'StorageDetail': 'Storage detail',
    'LastTenUpdates': 'Last 10 updates to the storage data view',
    'StorageDeviceUsageView': 'Storage device usage view',
    'HostedRepository': 'Hosted repository',
    'ProxyRepository': 'Proxy repository',
    'SourceToTarget': 'Source To Target Sync',
    'TargetToSource': 'Target To Source Sync',
    'TwoWaySync': 'Two Way Sync',
    'ArtifactsTotal': 'Artifacts count',
    'UsedFilesSize': 'File used size',
    'FilesTotal': 'Files count',
    'FoldersTotal': 'Folders count',
    'ItemsTotal': 'Items count',
    'ThanLastUpdate':'Than last update',
    'StorageDeviceCount':'Storage device count',
    'DownloadedCount':'Downloaded count',
    'LastTenArtifactUpdates': 'Last 10 updates to the artifact data view',
    'Each': 'Each',
    'RefreshImmediately':'Refresh immediately',
    'DeviceTotal':'Total',
    'Device':'device',
}