export default {
    'Error': '错误',
    'Login': '登录',
    'PersonalInformation': '个人信息',
    'LogOut': '退出登录',
    'OfficialWebsite': '官网',
    'HelpDocumentation': '帮助文档',
    'AboutUs': '关于我们',
    'TeamIntroduction': '团队介绍',
    'TrialApplication': '试用申请',
    'PurchaseFullVersion': '购买正式版',
    'UserManual': '使用文档',
}
