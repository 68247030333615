export default {
    'Products': '制品',
    'ProductsNum': '制品总数（个)',
    'Download': '下载',
    'DownloadsNum': '下载总数(次)',
    'Dependency': '依赖',
    'DependenciesNum': '依赖总数',
    'Bug': '漏洞',
    'BugNum': '漏洞总数',
    'WhiteList': '白名单',
    'bugWhitelist': '漏洞白名单',
    'Blacklist': '黑名单',
    'bugBlacklist': '漏洞黑名单',
}
