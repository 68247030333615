export default {
    // index.vue
    'groupNameQuery': 'Enter group name query',
    'componentNameQuery': 'Enter component name query',
    'versionQuery': 'Enter version query',
    'ComponentName': 'Component name',
    'VersionNumber': 'Version number',
    'Group': 'Group',
    'PackageURL': 'Package URL',
    'SWID': 'SWID',
    'ProjectName': 'Artifact Name',
    'RiskScore': 'Risk Score',
    'Vulnerability': 'Vulnerability',
    'GeneralView': 'General view',
    'Licence': 'Licence',
    // ComponentVulnerabilities.vue
    'ComponentVersion': 'Component version',
    'ComponentDescription': 'Component description',
    'AssociatedStorageSpace': 'Associated storage space',
    'AssociatedWarehouse': 'Associated warehouse',
    'RelatedArticles': 'Related articles',
    'List': 'List',
    'Total': 'Total',
    'Seriously': 'Seriously',
    'MediumRisk': 'Medium risk',
    'HighRisk': 'High risk',
    'LowRisk': 'Low risk',
    'Atlas': 'Atlas',
    'OperateSuccess': 'Operation successful',
    // AffectedArtifacts.vue
    'ArtifactPathQuery': 'Enter artifact path query',
    'StorageSpace': 'storage space',
    'OwnedWarehouse': 'Owned warehouse',
    'ProductPath': 'Product path',
    // ComponentVulnerabilities.vue
    'EnterVulnerabilityNumber': 'Enter the vulnerability number query',
    'VulnerabilityDescription': 'Vulnerability description',
    'CvssV2VulnerabilityLevel': 'CvssV2 vulnerability level',
    'CvssV3VulnerabilityLevel': 'CvssV3 vulnerability level',
    'CvssV2Score': 'CvssV2 score',
    'CvssV3Score': 'CvssV3 score',
    'ImportVersion': 'Import version',
    'VulnerabilityNumber': 'Vulnerability number',
    'IntroducingTime': 'Introducing time',
    'HighestVulnerabilityLevel': 'The highest vulnerability level',
    'RecommendedFixVersion': 'Recommended fix version',
}