export default {
    'SyncNo': 'Synchronized product numbering',
    'SourcePath': 'Source Product Path',
    'SourceStorageId': 'Source storage space',
    'SourceRepositoryId': 'Source repository',
    'TargetPath': 'Target Product Path Information',
    'OpsType': 'Type of product operation',
    'Status': 'Synchronized status',
    'SlaveRecordCleared': 'Slave node logging status',
    'SyncProgress': 'Synchronized progress',
    'FailedReason': 'Failed reason',
    'CreateTime': 'Create Time',
    'Operational': 'Operational',
    'Ready':'ready',
    'Syncing':'syncing',
    'Success':'success',
    'Failed':'failed',
    'Removals':'Removals',
    'NotCleared':'Not cleared',
    'ProductUpgrade':'Product upgrade',
    'Distribution':'Distribution of products',
    'SynchronousMode':'synchronous mode',
    'SetTop': 'Set Top',
    'EnterVulnerabilitySourceStorageId': 'Input source storage space',
    'EnterVulnerabilitySourceRepositoryId': 'Input source warehouse',
    'Uncharted': 'uncharted',
    'FailedCount' : 'Failed Count',
    'SuccessCount' : 'Success Count',
    'TotalCount' : 'Total Count',
    'FileSizeCount': 'Total file size (G)',
    '30daysData': 'Data in the last 30 days',
    '30DaysPromotionRanking': 'Top 10 Warehouses within 30 Advancement Rankings',
    'TrendsInPromotionStatusWithin30':'Trends in promotion status within 30',
    'Remove':'Remove',
   
    
}
