export default {
    // index.vue
    'ProjectNameQuery': 'Project Name Query',
    'ParentProjectQuery': 'Please select the parent project',
    'ProjectName': 'Artifact Name',
    'Tip': 'Tip',
    'VersionNumber': 'VersionNumber',
    'Classifier': 'Classifier',
    'LastBOMImport': 'Last BOM Import',
    'BOMFormat': 'BOM Format',
    // index.vue
    'GeneralView': 'General view',
    'Artifacts': 'Artifacts',
    'Component': 'Component',
    'Services': 'Services',
    'AuditVulnerabilities': 'Audit Vulnerabilities',
    'ExploitPredictions': 'Exploit Predictions',
    'PolicyViolations': 'Policy Violations',
    // ProjectDashboard.vue
    'ProjectVulnerabilities': 'Artifact Vulnerabilities',
    'LastBOMImport1': 'Last BOM Import: ',
    'LastMeasurement': 'Last Measurement: ',
    'Seriously': 'Seriously',
    'HighRisk': 'High Risk',
    'MediumRisk': 'Medium Risk',
    'LowRisk': 'Low Risk',
    'Unassigned': 'Unassigned',
    'RiskScore': 'Risk Score',
    'PolicyViolationsByState': 'Policy Violations By State',
    'PolicyViolationsByClassification': 'Policy Violations By Classification',
    'SecurityRisk': 'Security Risk',
    'LicenseRisk': 'License Risk',
    'OperationalRisk': 'Operational Risk',
    'AuditingProgress': 'Auditing Progress',

    // ProjectComponents.vue
    'componentNameQuery': 'Enter the component Name Query',
    'ComponentName': 'Component Name',
    'ComponentNameTip': 'The name of the component as provided by the supplier',
    'ComponentNameRequired': 'Component Name is required',
    'Version': 'Version',
    'LatestVersion': 'Latest Version',
    'Group': 'Group',
    'Internal': 'Internal',
    'Licence': 'Licence',
    'Vulnerabilities': 'Vulnerabilities',

    // ProjectServices.vue
    'EnterVulnerabilityNumber': 'Enter the vulnerability number query',
    'ServicesNameQuery': 'Services Name Query',
    'Vulnerability': 'Vulnerability',
    'Aliases': 'Aliases',
    'CWE': 'CWE',
    'Severity': 'Severity',
    'Analyzer': 'Analyzer',
    'AttributedOn': 'AttributedOn',
    'Analysis': 'Analysis',
    'Suppressed': 'Suppressed',
    'Suppressed_true': 'true',
    'Suppressed_false': 'false',

    // ProjectEpss.vue
    'EPSS': 'EPSS',
    'EPSSPercentile': 'EPSSPercentile',
    'CVSS': 'CVSS',

    'RiskType': 'Risk Type',
    'PolicyName': 'Policy Name',
    'OccurredOn': 'Occurred On',
    'NameQuery': 'Name Query',
    'CreateProject': 'Create Project',
    'Close': 'Close',
    'Create': 'Create',
    'NameRequired': 'The project name is required',
    'Parent': 'Parent',
    'Description': 'Description',
    'Tags': 'Tags',
    'ClassifierRequired': 'The Classifier is required',
    'VersionRequired': 'The Version is required',
    'ParentPlaceholder': 'Type to search parent',
    'General': 'General',
    'Identity': 'Identity',
    'NamespaceGroupVendor': 'Namespace / group / vendor',
    'Purl': 'Package URL (PURL)',
    'Cpe': 'Common Platform Enumeration (CPE)',
    'SwidTagId': 'SWID Tag ID',
    'UploadBOM': 'Upload BOM',
    'DownloadBOM': 'Download BOM',
    'Inventory': 'Inventory',
    'InventoryWithVulnerabilities': 'Inventory with Vulnerabilities',
    'AddComponent': 'Add Component',
    'DelComponent': 'Remove Component',
    'ComponentDetails': 'Component Details',
    'Extended': 'Extended',
    'Filename': 'Filename',
    'Legal': 'Legal',
    'License': 'License',
    'LicenseTip': 'Specifies the SPDX license ID of the component',
    'LicenseUrl': 'License URL',
    'LicenseUrlTip': 'Specifies the URL to the license of the component',
    'SPDX': 'SPDX Expression',
    'SPDXTip': 'Specifies license information for the component in the form of an SPDX expression',
    'Copyright': 'Copyright',
    'Hashes': 'Hashes',
    'MD5': 'MD5',
    'HashTip': 'Specifies the observed hash value of the component using the preceding algorithm',
    'SHA1': 'SHA1',
    'SHA256': 'SHA 256',
    'SHA512': 'SHA 512',
    'SHA3256': 'SHA3 256',
    'SHA3512': 'SHA3 512',
    'Notes': 'Notes',

    'Cancel': 'Cancel',
    'Reset': 'Reset',
    'Upload': 'Upload',
    'UploadPlaceholder': 'Please choose file',

    'ProjectDetail': 'Details',
    'DetailGeneral': 'General',
    'ExternalReferences': 'External References',
    'ProNameTip': 'The name of the project or component as provided by the supplier',
    'VersionTip': 'The version of the component as provided by the supplier',
    'ClassifierTip': 'Specifies the type of component: Assets (applications, operating systems, and hardware) and non-assets (libraries, frameworks, and files)',
    'Active': 'Active',
    'ObjectIdentifier': 'Object Identifier',
    'ObjectIdentifierTip': 'A unique identifier (UUID) that Dependency-Track automatically assigns to every object',
    'NGV': 'Namespace / group / vendor',
    'NGVTip': 'The suppliers higher-level namespace, group, or vendor identifier',
    'PURL': 'Package URL (PURL)',
    'PURLTip': 'A Valid Package URL is required for libraries and frameworks. PURL syntax: pkg:type/namespace/name@version?qualifiers#subpath',
    'CPE': 'Common Platform Enumeration (CPE)',
    'CPETip': 'The CPE v2.2 or v2.3 URI as provided by MITRE or NIST. All assets (applications, operating systems, and hardware) should have a CPE specified',
    'SWID': 'SWID Tag ID',
    'SWIDTip': 'The ISO/IEC 19770-2:2015 (SWID) tag ID provided by the software vendor',
    'URL': 'URL',
    'Type': 'Type',
    'ProProperty': 'Properties',
    'Name': 'Name',
    'Value': 'Value',
    'CreateProperty': 'Create Property',
    'Property': 'Properties',
    'Update': 'Update',
    'AddVersion': 'Add Version',
    'Delete': 'Delete',
    'GroupName': 'Group Name',
    'GroupNameRequired': 'Group name is required',
    'PropertyName': 'Property Name',
    'PropertyNameRequired': 'Property name is required',
    'PropertyValue': 'Property Value',
    'PropertyValueRequired': 'Property value is required',
    'PropertyType': 'Property Type',
    'PropertyTypeRequired': 'Property type is required',
    'CreateVersion': 'Create Version',
    'Includes': 'Includes ',
    'AuditHistory': 'audit history',
    'AccessControlList': 'access control list',
    'DependencyGraph': 'Dependency Graph',
    'HighlightComponents': 'Highlight outdated components',
    'Condition': 'Condition',
    'AuditTrail': 'Audit Trail',
    'Commit': 'Commit',
    'CommitPlaceholder': 'Leave a comment',
    'AddCommit': 'Add Commit',
    'NotSet': 'Not Set',
    'Approved': 'Approved',
    'Rejected': 'Rejected',
    'Overview': 'Overview',
    'ComponentVulnerabilities': 'Component Vulnerabilities',
    'Published': 'Published',
    'Author': 'Author',
    'AuthorTip': 'The author of the component',
    'ShowCompleteGraph': 'Show complete graph',
    'ViewDetail': 'View Detail',
    'FormatTime':'en-US',
    'ANALYSIS_NOT_SET': 'not set',
    'ANALYSIS_APPROVED': 'approved',
    'ANALYSIS_REJECTED': 'rejected',
    'Details': 'Details',
    'UpdateDetails': 'Update Details',
    'Exploitable': 'Exploitable',
    'InTriage': 'In Triage',
    'Resolved': 'Resolved',
    'FalsePositive': 'False Positive',
    'NotAffected': 'Not Affected',
    'Justification': 'Justification',
    'VendorResponse':'Vendor Response (project)',
    'CanNotFix': 'Can not fix',
    'WillNotFix':'Will not fix',
    'Rollback' : 'Rollback',
    'WorkaroundAvailable': 'Workaround available',
    'CodeNotPresent': 'Code not present',
    'CodeNotReachable': 'Code not reachable',
    'RequiresConfiguration': 'Requires configuration',
    'RequiresDependency': 'Requires dependency',
    'RequiresEnvironment' : 'Requires environment',
    'ProtectedByCompiler' : 'Protected by compiler',
    'ProtectedAtRuntime': 'Protected at runtime',
    'ProtectedAtPerimeter': 'Protected at perimeter',
    'ProtectedByMitigatingControl': 'Protected by mitigating control',
    'JustificationPrompt': 'The rationale of why the impact analysis state was asserted to be "Not Affected"',
    'VendorResponsePrompt':'A response to the vulnerability by the manufacturer, supplier，Or project responsible for the affected component or service',
    'DetailsPrompt': 'Details （explanation，workaround details, and other impact information）',
    'AnalysisPrompt':'The current state of an occurrence of a vulnerability',
    "PolicyType": "Type",
    "LicenseViolations":"License Violations",
    "OperationalViolations":"Operational Violations",
    "SecurityViolations":"Security Violations",
    "ViolationState":"Violation State",
    "InformationalViolations":"Informational Violations",
    "ViolationWarnings":"Violation Warnings",
    "ViolationFailures":"Violation Failures",
    "ViolationTotal":"Total",
    "UpdateSuccess": "Update Success",
    "ProjectChildren": "Project Children",
    'Repository': 'Repository ',
    'Artifact': 'Artifact ',
    'UpdateMessage': 'Update Success',
    'OperationFailed': 'Operation Failed',
    'DeleteSuccess': "Deleted successfully",
    'SearchPlaceholder': 'Search',
    'RepositoryName':'Repository Name',
    'RepositoryNameQuery':'Enter the Repository Name Query',
    'ArtifactNameQuery':'Enter the Artifact Name Query',
}
