export default {
    'EventRecord': 'Event record',
    'Distribution': 'Distribution ',
    'promotionRecords': ' promotion records',
    'DistributionNode': 'Distribution node',
    'StorageSpace': 'Storage space',
    'WarehouseName': 'Repository name',
    'CheckOut': 'Check out',
    'UnknownOperation': 'Unknown operation',
    'UnknownState': 'Unknown state',
    'Cleared': 'Cleared',
    'NotCleared': 'Not cleared',
    'CurrentProductIsSynchronizing': 'The current aritfact is synchronizing',
    'SureMakeProductCompensation': ' are you sure you want to make aritfact compensation?',
    'Confirm': 'Confirm',
    'Cancel': 'Cancel',
    'Compensation': 'Compensation',
    'AuditLog': 'Audit log',
    'ProductPromotion': 'Aritfact promotion',
    'DistributionOfProducts': 'Distribution of artifacts',
    'Ready': 'Ready',
    'InSync': 'In sync',
    'Success': 'Success',
    'Failure': 'Failure',
    'ProductSynchronousNumber': 'Aritfact synchronous number',
    'SourceProductPath': 'Source aritfact path',
    'TargetProductPathInformation': 'Target aritfact path information',
    'ProductOperation': 'Aritfact operation',
    'SynchronousState': 'Synchronous state',
    'FromTheRecordState': 'From the record state',
    'SynchronizingProgress': 'Synchronizing progress',
    'CauseOfFailure': 'Cause of failure',
    'CreationTime': 'Creation time',
    'Operation': 'Operation',
}
