export default {
    'ArtifactsScan': '制品扫描',
    'ArtifactsScanDesc': '制品纬度扫描漏洞、依赖、License等信息',
    'BOMScan': 'BOM扫描',
    'BOMScanDesc': 'BOM纬度扫描漏洞、依赖、License等信息',
    'On': '开启',
    'Off': '关闭',
    'Confirm': '确定',
    'Cancel': '取消',
    'Save': '保存',
    'OperationSuccessful': '操作成功',
}
