export default {
    'FederatedRepositoryArtifactPromotion': 'Federated repository artifact promotion',
    'FederatedRepositoryArtifactPromotionHasBeenInitiated': 'Federated repository artifact promotion has been initiated',
    'FederatedRepositoryArtifactPromotionHasBeenClosed': 'Federated repository artifact promotion has been closed',
    'On': 'On',
    'Off': 'Off',
    'PromotionRules': 'Promotion rules',
    'ArtifactPath': 'Artifact path',
    'ArtifactPathTips1': 'Enter keywords to match artifact paths, support regular expressions,',
    'ArtifactPathTips2': 'Common example: Example can match artifacts in the artifact path that contain examples,',
    'ArtifactPathTips3': 'Regular example: (.*example.*)(.*V1.*) Matches image names containing example,',
    'ArtifactPathTips4': 'The image version contains the image of V1',
    'Metadata': 'Metadata',
    'MetadataTips1': 'Enter metadata KEY and metadata value, match characters equally',
    'MetadataTips2': 'used to match artifacts that contain the key and value in artifact metadata',
    'ArtifactPath': 'Please enter the package paths',
    'PleaseEnter': 'Please enter ',
    'ArtifactPaths': 'Package paths',
    'MetadataKey': 'Metadata key',
    'MetadataValue': 'Metadata value',
    'FederatedRepositorySelect': 'Federated repository select',
    'FederatedRepositorySelectTips': 'Select artifacts repository from other clusters to form a federated repository',
    'InnerNodes': 'Inner nodes',
    'ThirdpartyNodes': 'Thirdparty nodes',
    'ArtifactRepositoryNode': ' artifact repository node',
    'OtherType': 'Other type ',
    'PleaseSlectRepository': 'Please slect repository',
    'Confirm': 'Confirm',
    'Cancel': 'Cancel',
    'Save': 'Save',
    'DeleteConfirm': 'Are you sure you want to delete it?',
    'Node': 'Node',
    'RepositoryType': 'Repository Type',
    'Storage': 'Storage',
    'Repository': 'Repository',
    'Operation': 'Operation',
    'AritfactPromotionRuleTips': 'Please select whether to enable aritfact promotion',
    'PromotionRuleTips': 'Please select the promotion rule',
    'ArtifactPathRulesTips': 'The package path length is between 1 and 200 characters',
    'MetadataKeyRulesTips': 'The metadata key length is between 1 and 100 characters',
    'MetadataValueRulesTips': 'The metadata value is between 1 and 200 characters in length',
    'OperateSuccess': 'Operate Success',
    'SyncData': 'Synchronize users, role permissions, user groups, and repositories to other clusters',
}
