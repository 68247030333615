export default {
    'Create': 'Add',
    'SureDelete': 'Are you sure you want to delete it?',
    'Cancel': 'Cancel',
    'Confirm': 'Confirm',
    'Username': 'Username',
    'CreationTime': 'Creation time',
    'Operation': 'Operation',
    'OperateSuccess': 'Operation successful',
    'ExternalNodeName': 'External node name',
    'ExternalNodeType': 'External node type',
    'ExternalNodeAddress': 'External node address',
    'deleteExternalNodeSuccess': 'Successfully deleted external node',
    'EnterYourUsername': 'Please enter your username',
    'Password': 'Password',
    'EnterThePassword': 'Please enter the password',
    'AddExternalNode': 'Add external node',
    'ModifyExternalNode': 'Modify external node',
    'EnterExternalNodeName': 'Please enter the name of the external node',
    'ArtifactRepositoryType': 'Artifact repository type',
    'selectArtifactType': 'Please select the artifact repository type',
    'EnterExternalNodeAddress': 'Please enter the address of the external node',
    'EnterValidURL': 'Please enter a valid URL',
    'addressLengthLimit': 'Length should be between 1 and 255 characters',
    'ExternalNodeAddSuccess': 'External node added successfully',
    'ExternalNodeModifySuccess': 'External node modified successfully',
}
