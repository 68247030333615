export default {
    // index.vue
    'groupNameQuery': '输入组名称查询',
    'componentNameQuery': '输入组件名称查询',
    'versionQuery': '输入版本号查询',
    'ComponentName': '组件名称',
    'VersionNumber': '版本号',
    'Group': '组',
    'PackageURL': '包地址',
    'SWID': '标签标识',
    'ProjectName': '制品名称',
    'RiskScore': '风险评分',
    'Vulnerability': '漏洞',
    'GeneralView': '总览',
    'Licence': '许可证',
    // ComponentVulnerabilities.vue
    'ComponentVersion': '组件版本',
    'ComponentDescription': '组件描述',
    'AssociatedStorageSpace': '关联存储空间',
    'AssociatedWarehouse': '关联仓库',
    'RelatedArticles': '关联制品',
    'List': '列表',
    'Total': '总数',
    'Seriously': '严重',
    'MediumRisk': '中危',
    'HighRisk': '高危',
    'LowRisk': '低危',
    'Atlas': '图谱',
    'OperateSuccess': '操作成功',
    // AffectedArtifacts.vue
    'ArtifactPathQuery': '输入制品路径查询',
    'StorageSpace': '存储空间',
    'OwnedWarehouse': '所属仓库',
    'ProductPath': '制品路径',
    // ComponentVulnerabilities.vue
    'EnterVulnerabilityNumber': '输入漏洞编号查询',
    'VulnerabilityDescription': '漏洞描述',
    'CvssV2VulnerabilityLevel': 'CvssV2漏洞等级',
    'CvssV3VulnerabilityLevel': 'CvssV3漏洞等级',
    'CvssV2Score': 'CvssV2评分',
    'CvssV3Score': 'CvssV3评分',
    'ImportVersion': '引入版本',
    'VulnerabilityNumber': '漏洞编号',
    'IntroducingTime': '引入时间',
    'HighestVulnerabilityLevel': '最高漏洞等级',
    'RecommendedFixVersion': '建议修复版本',
}