export default {
    'ProductManagement': '制品管理',
    'ProductWarehouse': '制品仓库',
    'SecurityScanning': '安全扫描',
    'OpenSourceGovernance': '制品分析',
    'BOMAnalysis': 'BOM分析',
    'ProductAnalysis': '制品扫描',
    'OpenSourceComponents': '开源组件',
    'VulnerabilityDatabase': '漏洞库',
    'CertificateStore': '证书库',
    'SetupManagement': '设置管理',
    'UserManagement': '用户管理',
    'Permissions': '权限管理',
    'Groups': '用户组管理',
    'AccessToken':'访问令牌',
    'TokenList':'令牌列表',
    'GlobalSettings': '全局设置',
    'HealthMonitoring': '健康监测',
    'SeniorOperations': '高级运维',
    'DoNotKnowHowToUseIt': '不知道怎么用?',
    'CheckTheHelpDocumentation': '请查阅帮助文档',
    'userManual': '使用文档',
    'UploadProgress': '上传进度',
    'FileList': '文件列表',
    'Empty': '清空',
    'ReasonsForFailure': '失败原因: ',
    'StyleSettings': '风格配置',
    'AdjustStyleYouLike': '调整你喜欢的样式.',
    'MenuDisplayStyle': '菜单显示风格',
    'CurrentlySupportsStyles': ' 目前支持2种风格,其他风格后续更新',
    'Transparent': '透明',
    'WhiteBackground': '白底',
    'NavigationLock': '导航锁定',
    'StorageMonitoring': '存储驾驶舱',
    'StatisticalOverview':'统计概览',
    'AdvancementCockpits':'晋级驾驶舱',
    'AdvancementCockpitsInfo':'晋级驾驶舱详情',
}
