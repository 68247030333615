export default {
    'Error': 'Error',
    'Login': 'Login',
    'PersonalInformation': 'Personal Information',
    'LogOut': 'Log out',
    'OfficialWebsite': 'Official website',
    'HelpDocumentation': 'Help documentation',
    'AboutUs': 'About us',
    'TeamIntroduction': 'Team introduction',
    'TrialApplication': 'Trial application',
    'PurchaseFullVersion': 'Purchase full version',
    'UserManual': 'User manual'
}
