export default {
    'ArtifactsScan': 'Artifacts scan',
    'ArtifactsScanDesc': 'Artifacts latitude scanning vulnerabilities, dependencies, licenses, and other information',
    'BOMScan': 'BOM scan',
    'BOMScanDesc': 'BOM latitude scanning vulnerabilities, dependencies, licenses, and other information',
    'On': 'On',
    'Off': 'Off',
    'Confirm': 'Confirm',
    'Cancel': 'Cancel',
    'Save': 'Save',
    'OperationSuccessful': 'Operation successful',
}
