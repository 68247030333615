export default {
    'AllowAccess': 'Allowing anonymous access',
    'AnonymousPull': 'Anonymous users can pull items',
    'CannotPullArtifacts': 'Anonymous users cannot pull artifacts',
    'On': 'On',
    'Off': 'Off',
    'VisibilityRange': 'Warehouse visibility range',
    'StorageMembers': 'Storage space members visible, pull-out',
    'AllMembers': 'All members are visible, pull-able',
    'StorageSpace': 'Storage space',
    'Public': 'Public',
    'PublicPermissionDefinition': 'Repository permission definition',
    'SpecificAuthority': "Define the user's permission to upload and delete the products of the warehouse.",
    'AddUser': 'Add a user',
    'SelectAll': 'Select all',
    'Path': 'Path',
    'NoPath': 'If the path is not specified, the permission is set for the entire warehouse',
    'HavePath': 'Enter the path, which is the permission setting for the path under the warehouse',
    'PathFormat': 'Support for multiple paths separated by commas (","),',
    'ExamplePath': 'example paths: example/a,example/b.',
    'havePermissions': 'Have the ',
    'UploadAndDelete': 'upload and delete permissions of the repository',
    'UploadPermissions': 'upload rights to the repository',
    'DeletePermissions': 'delete permission for the repository',
    'EnterPath': 'Please enter the path',
    'Yes': 'Yes',
    'No': 'No',
    'SureDelete': 'Are you sure you want to delete it?',
    'Confirm': 'Confirm',
    'Cancel': 'Cancel',
    'Save': 'Save',
    'SelectRepositoryVisibility': 'Select the repository visibility scope',
    'UserName': 'User name',
    'Upload': 'Upload',
    'Delete': 'Delete',
    'Operation': 'Operation',
    'OperationSuccessful': 'OperationSuccessful',
    'setUpSuccessful': 'The repository was set up successfully',
    'ModifyScope': 'Warehouse visibility range changed to within storage space',
    'removedUser': ' does not belong to this storage space and needs to be removed from the authorization list first',
    'Give': 'Give ',
    'permission': ' at least one permission',
    'AssigningMembers': 'There are no members satisfying the condition. First assign the appropriate members to the store',
}
