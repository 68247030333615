export default {
    'StrategyList': 'Strategy List',
    'Seriously': 'Seriously',
    'HighRisk': 'High Risk',
    'MediumRisk': 'Medium Risk',
    'LowRisk': 'Low Risk',
    'YES': 'YES',
    'NO': 'NO',
    'BlockingStrategyName': 'Blocking strategy name',
    'VulnerabilityBlockingLevel':'Vulnerability blocking level',
    'FilterVulnerabilityWhitelist':'Filter vulnerability whitelist',
    'FilterVulnerabilityBlacklist':'Filter vulnerability blacklist',
    'FilterLicenseWhitelist':'Filter license whitelist',
    'FilterLicenseBlacklist':'Filter license blacklist',
    'FullPackageName':'Full package name',
    'BlockingStrategySetting':'Blocking strategy setting',
    'EnterBlockingStrategyName':'Please enter the blocking strategy name',
    'ChooseBlockRepository':'Choose to block the repository',
    'SelectableRepositories':'Selectable repositories',
    'SelectedRepositories':'Selected repositories',
    'VulnerabilityBlockingLevelTip':'Select the vulnerability level to be blocked. If the vulnerability of the artifact contains the selected vulnerability level, the artifact will be prohibited from downloading',
    'FilterVulnerabilityList':'Filter vulnerability list',
    'FilterVulnerabilityListTip1':'Filtering the whitelist means that the vulnerability numbers added to the vulnerability whitelist will be filtered out. If the vulnerability number of the artifact is included in the whitelist, the artifact can still be downloaded',
    'FilterVulnerabilityListTip2':'Filtering the blacklist means that the vulnerability numbers added to the vulnerability blacklist will be filtered out. If the vulnerability number of the artifact is included in the blacklist, the artifact will be prohibited from downloading',
    'Whitelist':'Whitelist',
    'Blacklist':'Blacklist',
    'FullPackageNameBlocking':'Full package name blocking',
    'FullPackageNameBlockingTip':'After setting, a comparison will be made between the package name and the package name blocking rule. If the package name of the artifact is included in the package name blocking rule, the artifact will be prohibited from downloading',
    'ChooseToBlockPackageName':'Choose to block package name',
    'ChooseToBlockPackageNameTip':'Select the package name rule to block. If the package name of the artifact is included in the selected package name blocking rule, the artifact will be prohibited from downloading',
    'SelectablePackageName':'Selectable package name',
    'SelectedPackageName':'Selected package name',
    'FilterLicenseList':'Filter License List',
    'FilterLicenseListTip1':'Filtering the whitelist means that licenses added to the License whitelist will be filtered out. If the artifacts license is included in the whitelist, the artifact can still be downloaded',
    'FilterLicenseListTip2':'Filtering the blacklist means that licenses added to the License blacklist will be filtered out. If the artifacts license is included in the blacklist, the artifact will be prohibited from downloading',
    'ChooseToBlockLicense':'Choose to block license',
    'ChooseToBlockLicenseTip':'Select the license to block. If the license of the artifact is included in the selected license, the artifact will be prohibited from downloading',
    'SelectableLicense':'Selectable license',
    'SelectedLicense':'Selected license',
    'EnterBlockStrategyNameQuery': 'Enter block strategy name query',
    'EnterStorageIdQuery': 'Enter storage query',
    'EnterRepositoryIdQuery': 'Enter repository query',
    'EnterPackageNameQuery': 'Enter package name query',
    'EnterLicenseIdQuery': 'Enter license query',
    'Operate': 'Operation',
    'OperationSuccessful': 'Operation successful',
}