export default {
    'AddToken': '添加令牌',
    'QueryTokenId': '输入令牌标识',
    'TokenId': '令牌标识',
    'Token':'令牌',
    'Description': '令牌描述',
    'ExpireTime': '过期时间',
    'CreateTime': '创建时间',
    'Operation': '操作',
    'Username': '所属用户',
    'Operator': '操作人',
    'SevenDay':'7 天',
    'ThirtyDay':'30 天',
    'NinetyDay':'90 天',
    'OneYear': '1 年',
    'Never':'不过期',
    'Close':'关闭',
    'DescriptionPlaceholder':'请输入令牌描述',
    'ExpireTimePlaceholder':'请选择过期时间',
    'UsernamePlaceholder':'请输入所属用户',
    'NewTokenWarning':'新令牌已创建。请确保现在复制它，因为之后您将无法再次看到它！'
}
