export default {
    'Products': 'Products',
    'ProductsNum': 'Total number of products (PCS)',
    'Download': 'Download',
    'DownloadsNum': 'Total number of downloads (times)',
    'Dependency': 'Dependency',
    'DependenciesNum': 'Total number of dependencies',
    'Bug': 'Bug',
    'BugNum': 'Total number of vulnerabilities',
    'WhiteList': 'White list',
    'bugWhitelist': 'vulnerability whitelist',
    'Blacklist': 'Blacklist',
    'bugBlacklist': 'Vulnerability blacklist',
}
