export default {
    'UsingUsernamePasswordLogin': '使用用户名和密码进行登录操作',
    'LocalAuthentication': '本地认证',
    'enterLDAPUsername': '请输入LDAP用户名!',
    'Welcome': '欢迎',
    'CheckAgreementProceed.': '请勾选同意本协议',
    'Login': '登录',
    'Username': '用户名',
    'EnterYourUsername': '请输入用户名!',
    'Password': '密码',
    'EnterThePassword': '请输入密码!',
    'Authentication': '认证',
}
