const language = {
    state: {
        lang: 'zh'
    },

    mutations: {
        changeLanguage(state, value) {
            state.lang = value
        },
    }
}

export default language
