export default {
    'Save': '保存',
    'Cancel': '取消',
    'BeSure': '确定',
    'HasBeenUsed': '已使用',
    'AboutPercentageOf': '约占',
    'EnableCaching': '开启缓存',
    'sureClearCacheDirectory': '确定要清空缓存目录',
    'cacheDirectory': '吗?',
    'Empty': '清空',
    'CacheDirectories': '缓存目录',
    'containerizedDeployment': '如果是容器化部署，目录则为容器内目录，需要在容器启动时将缓存目录挂载到容器内部的对应目录',
    'selectCacheDirectory': '请选择缓存目录',
    'CacheCapacity': '缓存容量',
    'enterCacheCapacity': '请输入缓存容量',
    'MinimumCacheValuePerFile': '单文件最小缓存值',
    'GreaterThanOrEqualToMinimumCacheValue': '大于等于单文件最小缓存值的制品才会被放入缓存中',
    'enterMinimumCacheValue': '请输入单文件最小缓存值',
    'MaximumCacheValueForSingleFile': '单文件最大缓存值',
    'LessThanOrEqualMaximumCacheValue': '小于等于单文件最大缓存值的制品才会被放入缓存中',
    'enterMaximumCacheValue': '请输入单文件最大缓存值',
    'CleaningCondition': '清理条件（百分比）',
    'EnterCleaningCapacity': '输入1-100的值，例如输入90，表示大于等于缓存容量的90%时开始清理',
    'enterCleaningCondition': '请输入清理条件（百分比）',
    'CleanupRatio': '清理比例（百分比）',
    'AtLeastTheClearedCacheCapacity': '输入1-100的值，例如输入10，表示达到清理条件时，至少清理缓存容量的10%，可能会大于10%',
    'enterCleaningRatioPercent': '请输入清理比例（百分比）',
    'KeepMinimumUnitsConsistent': '请保持最小缓存值单位与最大缓存值单位一致',
    'MinimumCacheValue': '最小缓存值需小于最大缓存值',
    'KeepMaximumUnitsConsistent': '请保持最大缓存值单位与最小缓存值单位一致',
    'MaximumCacheValue': '最大缓存值需大于最小缓存值',
    'CacheSizeLengthLimit': '缓存容量长度在1到10个字符',
    'enterCleaningConditions': '请输入清理条件',
    'enterCleaningRatio': '请输入清理比例',
    'ClearedCacheDirectorySuccess': '清空缓存目录成功',
    'cachePolicySetSuccess': '缓存策略设置成功',
    'OperationSuccessful': '操作成功',

}
