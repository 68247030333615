export default {
    'CreatePackage': '新增包名',
    'SureDelete': '确定要删除吗?',
    'Cancel': '取消',
    'Confirm': '确定',
    'PackageName': '包名称',
    'PackageNamePlaceholder': '请输入包名称',
    'PackageNameLength': '包名称长度在1到300个字符',
    'ConditionType': '条件类型',
    'ConditionTypePlaceholder': '请选择条件类型',
    'Version': '版本号',
    'VersionPlaceholder': '请输入版本号',
    'VersionLength': '版本号长度在1到100个字符',
    'OperateSuccess': '操作成功',
    'Operate': '操作',
    'Success': ' 成功',
    'PackageNameQuery': '输入包名称查询',
    'DeletePackage':'删除包名',
}