import {formatTimestamp} from "@/utils/util";

export default {
    // index.vue
    'ProjectNameQuery': '输入项目名称查询',
    'ParentProjectQuery': '请选择父项目',
    'ProjectName': '制品名称',
    'Tip': '标签',
    'VersionNumber': '版本号',
    'Classifier': '分类',
    'LastBOMImport': '上一次导入清单',
    'BOMFormat': '清单格式',
    'RiskScore': '风险评分',
    'PolicyViolations': '违反策略',
    'Vulnerabilities': '漏洞',
    // index.vue
    'GeneralView': '总览',
    'Artifacts': '制品列表',
    'Component': '组件',
    'Services': '服务',
    'AuditVulnerabilities': '漏洞审核',
    'ExploitPredictions': '漏洞利用预测',
    // ProjectDashboard.vue
    'ProjectVulnerabilities': '违反政策',
    'LastBOMImport1': '上次BOM导入: ',
    'LastMeasurement': '上次统计: ',
    'Seriously': '严重',
    'HighRisk': '高危',
    'MediumRisk': '中危',
    'LowRisk': '低危',
    'Unassigned': '未分配',
    'PolicyViolationsByState': '政策违规情况',
    'PolicyViolationsByClassification': '策略违规分类',
    'SecurityRisk': '安全性',
    'LicenseRisk': '许可证风险',
    'OperationalRisk': '操作风险',
    'AuditingProgress': '审核进度',

    // ProjectComponents.vue
    'componentNameQuery': '输入组件名称查询',
    'ComponentName': '组件名称',
    'ComponentNameTip': '供应商提供的组件名称',
    'ComponentNameRequired': '组件名称为必填项',
    'Version': '版本',
    'LatestVersion': '最新版本',
    'Group': '组',
    'Internal': '内部',
    'Licence': '许可证',

    // ProjectServices.vue
    'ServicesNameQuery': '输入服务查询',
    'Name': '名称',
    'Authenticated': '认证',
    'CrossTrustBoundary': '跨信任边界',

    // ProjectFindings.vue
    'EnterVulnerabilityNumber': '输入漏洞编号查询',
    'Vulnerability': '漏洞编号',
    'Aliases': '别名',
    'CWE': '弱点枚举',
    'Severity': '漏洞类别',
    'Analyzer': '分析器',
    'AttributedOn': '归属于',
    'Analysis': '分析',
    'Suppressed': '屏蔽',
    'Suppressed_true': '是',
    'Suppressed_false': '否',


    // ProjectEpss.vue
    'EPSS': '漏洞利用预测评分',
    'EPSSPercentile': '漏洞利用概率百分位',
    'CVSS': '通用漏洞评分',

    'RiskType': '风险类型',
    'PolicyName': '政策名称',
    'OccurredOn': '发行时间',
    'NameQuery': '输入名称查询',
    'CreateProject': '创建项目',
    'Close': '取消',
    'Create': '确定',
    'NameRequired': '请输入项目名称',
    'Parent': '父项',
    'Description': '描述',
    'Tags': '标签',
    'ClassifierRequired': '请选择分类',
    'VersionRequired': '请输入版本',
    'ParentPlaceholder': '键入以搜索父项',
    'General': '一般',
    'Identity': '特性',
    'NamespaceGroupVendor': 'Namespace / group / vendor',
    'Purl': 'Package URL (PURL)',
    'Cpe': 'Common Platform Enumeration (CPE)',
    'SwidTagId': 'SWID Tag ID',
    'UploadBOM': '上传 BOM',
    'DownloadBOM': '下载 BOM',
    'Inventory': '清单',
    'InventoryWithVulnerabilities': '漏洞清单',
    'AddComponent': '创建组件',
    'DelComponent': '删除组件',
    'ComponentDetails': 'Component Details',
    'Extended': '扩展',
    'Filename': '文件名',
    'FilenameTip': '指定观察到的组件文件名',
    'Legal': '法律',
    'License': 'License',
    'LicenseTip': '指定组件的 SPDX 许可证 ID',
    'LicenseUrl': 'License URL',
    'LicenseUrlTip': '指定组件许可证的 URL',
    'SPDX': 'SPDX 表达',
    'SPDXTip': '以 SPDX 表达式的形式指定组件的许可证信息',
    'Copyright': 'Copyright',
    'Hashes': '哈希',
    'MD5': 'MD5',
    'HashTip': '使用上述算法指定组件的观测哈希值',
    'SHA1': 'SHA1',
    'SHA256': 'SHA 256',
    'SHA512': 'SHA 512',
    'SHA3256': 'SHA3 256',
    'SHA3512': 'SHA3 512',
    'Notes': '备注',

    'Cancel': '取消',
    'Reset': '重置',
    'Upload': '上传',
    'UploadPlaceholder': '请选择文件',

    'ProjectDetail': '详情',
    'DetailGeneral': '常规',
    'ExternalReferences': '外部引用',
    'ProNameTip': '供应商提供的制品或组件的名称',
    'VersionTip': '供应商提供的组件版本',
    'ClassifierTip': '指定组件类型：资产（应用程序、操作系统和硬件）和非资产（库、框架和文件）',
    'Active': '开启',
    'ObjectIdentifier': '对象标识符',
    'ObjectIdentifierTip': 'Dependency-Track 自动分配给每个对象的唯一标识符 （UUID）',
    'NGV': '命名空间 / 组 / 供应商',
    'NGVTip': '供应商更高级别的命名空间、组或供应商标识符',
    'PURL': '包 URL (PURL)',
    'PURLTip': '库和框架需要有效的包 URL。PURL 语法：pkg:type/namespace/name@version?qualifiers#subpath',
    'CPE': '通用平台枚举 (CPE)',
    'CPETip': 'MITRE 或 NIST 提供的 CPE v2.2 或 v2.3 URI。所有资产（应用程序、操作系统和硬件）都应指定 CPE',
    'SWID': 'SWID 标签 ID',
    'SWIDTip': '软件供应商提供的 ISO/IEC 19770-2: 2015 （SWID） 标签 ID',
    'URL': 'URL',
    'Type': '类型',
    'ProProperty': '属性',
    'Value': '值',
    'CreateProperty': '创建属性',
    'Property': '属性',
    'Update': '更新',
    'AddVersion': '添加版本',
    'Delete': '删除',
    'GroupName': '组名',
    'GroupNameRequired': '组名为必填项',
    'PropertyName': '属性名',
    'PropertyNameRequired': '属性名为必填项',
    'PropertyValue': '属性值',
    'PropertyValueRequired': '属性值为必填项',
    'PropertyType': '属性类型',
    'PropertyTypeRequired': '属性类型为必填项',
    'CreateVersion': '创建版本',
    'Includes': '包含',
    'AuditHistory': '审计记录',
    'AccessControlList': '访问控制列表',
    'DependencyGraph': '依赖关系图',
    'HighlightComponents': '标识过期的组件',
    'Condition': '条件',
    'AuditTrail': '审计跟踪',
    'Commit': '评论',
    'CommitPlaceholder': '留下评论',
    'AddCommit': '添加评论',
    'NotSet': '未设置',
    'Approved': '批准',
    'Rejected': '拒绝',
    'Overview': '概览',
    'ComponentVulnerabilities': '组件漏洞',
    'Published': '已发布',
    'Author': '作者',
    'AuthorTip': '组件的作者',
    'ShowCompleteGraph': '显示完整图表',
    'ViewDetail': '查看详情',
    'FormatTime':'zh',
    'ANALYSIS_NOT_SET': '未设置',
    'ANALYSIS_APPROVED': '已通过',
    'ANALYSIS_REJECTED': '已拒绝',
    'Details': '详情',
    'UpdateDetails': '更新详情',
    'DetailsPlaceholder': '更新详情',
    'Exploitable': '可利用',
    'InTriage': '正在评估',
    'Resolved': '已解决',
    'FalsePositive': '误报',
    'NotAffected': '不受影响',
    'Justification': '理由',
    'VendorResponse': '供应商回应',
    'CanNotFix': '无法修复',
    'WillNotFix':'不会修复',
    'Rollback' : '回滚',
    'WorkaroundAvailable': '可供选择的解决方案',
    'CodeNotPresent': '代码不存在',
    'CodeNotReachable': '代码不可用',
    'RequiresConfiguration': '需要配置',
    'RequiresDependency': '需要依赖',
    'RequiresEnvironment' : '需要环境',
    'ProtectedByCompiler' : '受编译器保护',
    'ProtectedAtRuntime': '在运行时受保护',
    'ProtectedAtPerimeter': '在外围受保护',
    'ProtectedByMitigatingControl': '由缓解控制保护',
    'JustificationPrompt': '影响分析状态不受影响 "的理由"',
    'VendorResponsePrompt':'制造商、供应商或负责受影响组件或服务的制品对漏洞的响应',
    'DetailsPrompt': '详细信息（解释，解决方法细节，以及其他影响信息)',
    'AnalysisPrompt':'漏洞的当前状态',
    "PolicyType": "类型",
    "LicenseViolations":"许可证违规",
    "OperationalViolations":"操作违规",
    "SecurityViolations":"安全违规",
    "ViolationState":"违规状态",
    "InformationalViolations":"信息违规",
    "ViolationWarnings":"违规警告",
    "ViolationFailures":"违规失败",
    "ViolationTotal":"总计",
    'UpdateSuccess': '更新成功',
    'ProjectChildren': '子项目',
    'Repository': '仓库',
    'Artifact': '制品',
    'UpdateMessage': '更新成功',
    'OperationFailed': '操作失败',
    'DeleteSuccess': "删除成功",
    'SearchPlaceholder': '搜索',
    'RepositoryName':'仓库名称',
    'RepositoryNameQuery':'请输入仓库名称查询',
    'ArtifactNameQuery':'请输入制品名称查询',
}

