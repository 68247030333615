export default {
    'Create': '新增',
    'SureDelete': '确定要删除吗?',
    'Cancel': '取消',
    'Confirm': '确定',
    'Username': '用户名',
    'CreationTime': '创建时间',
    'Operation': '操作',
    'OperateSuccess': '操作成功',
    'ExternalNodeName': '外部节点名称',
    'ExternalNodeType': '外部节点类型',
    'ExternalNodeAddress': '外部节点地址',
    'deleteExternalNodeSuccess': '删除外部节点成功',
    'EnterYourUsername': '请输入用户名',
    'Password': '密码',
    'EnterThePassword': '请输入密码',
    'AddExternalNode': '新增外部节点',
    'ModifyExternalNode': '修改外部节点',
    'EnterExternalNodeName': '请输入外部节点名称',
    'ArtifactRepositoryType': '制品库类型',
    'selectArtifactType': '请选择制品库类型',
    'EnterExternalNodeAddress': '请输入外部节点地址',
    'EnterValidURL': '请输入正确的URL',
    'addressLengthLimit': '长度在 1 到 255 个字符',
    'ExternalNodeAddSuccess': '新增外部节点成功',
    'ExternalNodeModifySuccess': '修改外部节点成功',
}

