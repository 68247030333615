export default {
    'StorageMonitoring': '存储驾驶舱',
    'StorageDevice': '存储设备',
    'StorageDeviceName': '存储设备名称',
    'StorageDeviceType': '存储设备类型',
    'StorageDeviceSize': '存储块总容量',
    'StorageDeviceUsableSize': '存储可用容量',
    'UsedStorageDeviceSize': '存储已用容量',
    'UsedStorageDeviceSizePercentage': '存储已用占比',
    'ArtifactFilesSize': '制品已用容量',
    'UsedArtifactSizePercentage': '制品已用占比',
    'StorageCount': '存储空间数量',
    'RepositoryCount': '制品仓库数量',
    'OtherFilesSize': '其他文件容量',
    'UsedOtherFilesSizePercentage': '其他文件占比',
    'Storage':'存储空间',
    'Repository':'所属仓库',
    'RepositoryLayout':'包类型',
    'RepositoryType':'仓库类型',
    'UsedStorageQuotaSizePercentage':'配额占比',
    'UsedStorageSizePercentage':'磁盘占比',
    'FilesSize':'文件大小',
    'FilesCount':'文件数量',
    'ArtifactsSize':'制品大小',
    'ArtifactsCount':'制品数量',
    'FoldersCount':'文件夹数量',
    'ItemsCount':'条目数量',
    'StorageOverview':'存储概览',
    'Total':'总计',
    'TrashCan':'回收站',
    'DataUpdateTime': '数据更新时间',
    'RefreshData': '已开始后台刷新数据',
    'StorageQuery': '请选择存储空间查询',
    'RepositoryQuery': '请选择仓库查询',
    'RepositoryLayoutQuery': '请选择包类型查询',
    'RepositoryTypeQuery': '请选择仓库类型查询',
    'StorageDetail': '存储详情',
    'LastTenUpdates': '近10次更新存储数据视图',
    'StorageDeviceUsageView': '存储设备使用情况视图',
    'HostedRepository': '本地库',
    'ProxyRepository': '代理库',
    'SourceToTarget': '源同步到目标',
    'TargetToSource': '目标同步到源',
    'TwoWaySync': '双向同步',
    'ArtifactsTotal': '制品总计数量',
    'UsedFilesSize': '文件已用容量',
    'FilesTotal': '文件总计数量',
    'FoldersTotal': '文件夹总数量',
    'ItemsTotal': '条目总计数量',
    'ThanLastUpdate':'比上次更新',
    'StorageDeviceCount':'存储设备数量',
    'DownloadedCount':'制品下载次数',
    'LastTenArtifactUpdates': '近10次更新制品数据视图',
    'Each': '个',
    'RefreshImmediately':'立即刷新',
    'DeviceTotal':'共',
    'Device':'个设备',
}