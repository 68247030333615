export default {
    'ProductWarehouse': 'Artifact repository',
    'SecurityScanning': 'Security scanning',
    'UserManagement': 'User management',
    'UserList': 'User list',
    'Permissions': 'Permissions management',
    'PermissionList': 'Permission List',
    'Groups': 'Groups management',
    'GroupList': 'Group List',
    'AccessToken':'Access Token',
    'TokenList':'Token List',
    'GlobalSettings': 'Global Settings',
    'SetupManagement': 'Setup management',
    'HealthMonitoring': 'Health monitoring',
    'SeniorOperations': 'Senior Operations',
    'ProductAnalysis': 'Artifact scan',
    'OpenSourceGovernance': 'Artifact analysis',
    'BOMAnalysis': 'BOM analysis',
    'VulnerabilityDatabase': 'Vulnerability database',
    'CertificateStore': 'Certificate store',
    'StorageAnalysis': 'Storage analysis',
    'Homepage': 'Home page',
    'RepositoryList': 'Repository list',
    'WarehouseBrowsing': 'Repository browsing',
    'ScanTheHomePage': 'Scan home page',
    'ScanDetails': 'Scan details',
    'Login': 'Login',
    'PersonalCenter': 'Personal center',
    'ProductDetails': 'Artifact details',
    'OpenSourceComponents': 'Open source components',
    'ComponentDetails': 'Component details',
    'VulnerabilityDetails': 'Vulnerability details',
    'CertificateDetails': 'Certificate details',
    'PromptInformation': 'Prompt information',
    'upgradePremiumVersion': 'This feature is for the premium version, if you need to experience, please upgrade to the premium version',
    'Well': 'Well',
    //bom start
    'ProjectsAnalysis': 'BOM Analysis',
    'ProjectsAnalysisDetail': 'Artifact Analysis Detail',
    'ProjectsDetails': 'Artifact Analysis',
    'RepositoryAnalysisDetail': 'Repository Analysis Detail',
    'RepositoryDetails': 'Repository Analysis',
    //bom end
    'StorageMonitoring': 'Storage Cockpits',
    'StatisticalOverview':'Statistical Overview',
    'AdvancementCockpits':'Advancement Cockpits',
    'AdvancementCockpitsInfo':'Advancement Cockpits Info',
}
